import { Alert, Button, createStyles, Group, Paper, SimpleGrid, Text, Title } from "@mantine/core";
import { IconAlertCircle, IconCoin, IconInfoCircle, IconPlus, IconSchool, IconUsers } from "@tabler/icons-react";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../App";
import { Link } from "react-router-dom";
import axios from "../../utils/axios";

const useStyles = createStyles((theme) => ({
    root: {
      padding: 0,
      marginBottom: 20,
    },
  
    value: {
      fontWeight: 700,
      lineHeight: 1,
    },
  
    diff: {
      lineHeight: 1,
      display: 'flex',
      alignItems: 'center',
    },
  
    icon: {
      color: theme.colorScheme === "light" ? theme.colors.gray[9] : theme.colors.gray[0]
    },
  
    title: {
      fontWeight: 700,
      textTransform: 'uppercase',
    },
}));

const Statistics = () => {
    const { classes } = useStyles();

    const { state } = useContext(AuthContext)

    const [assistants, setAssistants] = useState(0);

    useEffect(() => {
        axios.get("/assistants/all", {
            headers: {
                'Authorization': `Bearer ${state.userToken}`
            },
        }).then(function(res){
            if(res.status === 200){
                setAssistants(res.data.data.length);
            }
        }).catch(function(err){
            console.log(err);
        })
    }, [])

    return (
        <div className={classes.root} style={{overflowX: "auto"}}>
            <SimpleGrid cols={4} spacing={"xs"} breakpoints={[
                { maxWidth: "md", cols: 2 },
                { maxWidth: "xs", cols: 1 }
            ]} mb="md">
                <Paper withBorder p="sm" style={{borderLeft: `2px solid green`}}>
                    <Group position="apart">
                        <Text size={"xs"} className={classes.title}>
                            Assistants
                        </Text>
                        <IconUsers className={classes.icon} size={18} stroke={1} />
                    </Group>

                    <Group align="flex-end" spacing={"xs"} mt={25}>
                        <Text className={classes.value}>{assistants}</Text>
                    </Group>
                </Paper>

                <Paper withBorder p="sm" style={{borderLeft: `2px solid blue`}}>
                    <Group position="apart">
                        <Text size={"xs"} className={classes.title}>
                            CPDs
                        </Text>
                        <IconSchool className={classes.icon} size={18} stroke={1} />
                    </Group>

                    <Group align="flex-end" spacing={"xs"} mt={25}>
                        <Text className={classes.value}>0</Text>
                    </Group>
                </Paper>

                <Paper withBorder p="sm" style={{borderLeft: `2px solid yellow`}}>
                    <Group position="apart">
                        <Text size={"xs"} className={classes.title}>
                            Arrears
                        </Text>
                        <IconCoin className={classes.icon} size={18} stroke={1} />
                    </Group>

                    <Group align="flex-end" spacing={"xs"} mt={25}>
                        <Text className={classes.value}>0</Text>
                    </Group>
                </Paper>

                <Paper withBorder p="sm" style={{borderLeft: `2px solid red`}}>
                    <Group position="apart">
                        <Text size={"xs"} className={classes.title}>
                            Penalties
                        </Text>
                        <IconAlertCircle className={classes.icon} size={18} stroke={1} />
                    </Group>

                    <Group align="flex-end" spacing={"xs"} mt={25}>
                        <Text className={classes.value}>0</Text>
                    </Group>
                </Paper>
            </SimpleGrid>
        </div>
    )
}

const CTA = () => {
    return (
        <SimpleGrid mt={20} cols={2} breakpoints={[
            { maxWidth: 980, cols: 1 }
        ]}>
            <Paper p="md" withBorder>
            <div style={{height: 200}}>
            <Title order={4} mt={10} mb={20}>Assistants</Title>
            <Text mb={20}>Manage your approved assistants in a single platform. Add, update or remove your assistants easily.</Text>
            </div>
            <Button variant="outline" component={Link} to="/portal/assistants" leftIcon={<IconPlus />} radius={28}>Manage Assistants</Button>
 
            </Paper>

            <Paper p="md" withBorder>
            <div style={{height: 200}}>
            <Title order={4} mt={10} mb={20}>CPDs</Title>
            <Text mb={20}>Keep track of your Continuing Professional Development(CPD) points.</Text>
            </div>
            <Button variant="outline" component={Link} to="/portal/cpds" leftIcon={<IconSchool />} radius={28}>My CPDs</Button>
 
            </Paper>
        </SimpleGrid>
    )
}

export default function Portal(){
    const { state } = useContext(AuthContext);
    return (
        <>
        <Title mb="md" order={5}>Welcome, {state.userData?.salutation || "Mr."} {state.userData.fullname}</Title>
        <Statistics />
        {state.userData.status === "Pending" ? (
                      <Alert color={"red"} icon={<IconInfoCircle />} title="Pending Verification">
                        Your account is not yet verified. Once your account is verified, your status will change to 'Active'. This will allow you to interact with this platform, more.
                    </Alert>
        ) : null}
        <CTA />
        </>
    )
}