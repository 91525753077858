import { Anchor, BackgroundImage, Button, Center, Container, Divider, Group, MediaQuery, Paper, PasswordInput, SimpleGrid, Space, Stack, Text, TextInput, Title } from "@mantine/core";
import { useLocalStorage, useViewportSize } from "@mantine/hooks";
import { Helmet } from "react-helmet";

import Network from "../../assets/authentication.png";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { customAlphabet } from "nanoid";
import { currentBrowser, OS } from "../../utils/osAndBrowser";
import axios from "../../utils/axios";
import toast from "react-hot-toast";

const nanoid = customAlphabet("1234567890");

export default function ForgotPassword(){
    const { height } = useViewportSize();

    const [step, setStep] = useLocalStorage({
        key: "allsk-fp-step",
        defaultValue: 0,
        getInitialValueInEffect: true
    });

    const [email, setEmail] = useLocalStorage({
        key: "allsk-fp-email",
        defaultValue: "",
        getInitialValueInEffect: true
    });


    const [token, setToken] = useState("");

    const [password, setPassword] = useState("");

    const [confirm_password, setConfirmPassword] = useState("");

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const sendVerifyEmail = async () => {

        if(!/^\S+@\S+$/.test(email)){
          toast.error("Invalid email detected", {
            position: "bottom-right"
          })
           return false;
        }
      
        setLoading(true);
      
        axios.post("/member/code", {
          name: "",
          password: true,
          username: email,
          code: nanoid(4),
          browser: currentBrowser(window),
          os: OS(window),
        }).then(function(res){
          if(res.status === 200){
            toast.success("A verification code has been send to "+email, {
              position: "bottom-right",
              duration: 5000
            });
            setLoading(false);
            setStep(1)
          } else {
            toast.error("The email is already registered and in use!", {
              position: "bottom-right",
              duration: 5000
            })
            setLoading(false)
          }
        }).catch(function(error){
          toast.error(error?.response?.data?.message || error.message, {
            position: "bottom-right",
            duration: 5000
          })
          setLoading(false)
        })
      
    }

    const verifyEmail = () => {
        if(token.length < 4){
          toast.error("The token length should be atleast 4 characters", {
            position: "bottom-right",
          });
      
          return false
        }
      
        setLoading(true);
        axios.post("/member/verify", {
          username: email,
          token: token
        }).then(function(res){
          if(res.status === 200){
            toast.success("Verification succeeded!", {
              position: "bottom-right",
              duration: 5000
            });
            setLoading(false)
            setStep(2);
          }
        }).catch(function(error){
          toast.error(error?.response?.data?.message || error.message, {
            position: "bottom-right",
            duration: 5000
          });
          setLoading(false)
          setStep(0);
        })
      }

    const updatePassword = () => {
      setLoading(true);

      axios.post("/member/reset-password", {
        username: email,
        password: password
      }).then(function(res){
        if(res.status === 200){
          toast.success("Your password was updated successfully!", {
            position: "bottom-right",
          });
          navigate("/login");
          setLoading(false);
        }
      }).catch(function(error){
        toast.error(error?.response?.data?.message || error.message, {
          position: "bottom-right",
          duration: 5000
        });
        setLoading(false)
        setStep(0);
      })
    }

    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>SIGN UP | ALLSK</title>
            <meta name="description" content="Login to your member account" />

        </Helmet>

        <SimpleGrid cols={2} spacing={"lg"} breakpoints={[{maxWidth: 980, cols: 1, spacing: "md"}]}>
            <MediaQuery smallerThan={"md"} styles={{display: "none"}}>
                <BackgroundImage src="" bg={"blue"} h={height}>
                    <Stack p="xl" h={height} justify="space-between">
                        <Title color="#ffffff" order={6}>ALLSK Portal</Title>
                        <Center>
                              <img src={Network} className='h-96 w-auto' />
                        </Center>

                        <Text pb={40} color='#ffffff'>
                        Access Your Member Benefits, Resources, and Professional Network
                        </Text>
                    </Stack>
                </BackgroundImage>
            </MediaQuery>

            <Paper p="xl">
                <Group className='py-8 lg:py-0' position='right'>
                    <Center>
                        <Anchor component={Link} to={"/"} size={"sm"}>Login!</Anchor>
                    </Center>
                </Group>

                <Container size={400} className='h-screen-2xl py-24 lg:py-16 items-center justify-center'>
                <Title align='center' mb={10} order={4}>Forgot your password?</Title>
                <Text mb={20} align='center'>Submit your registered email and we will send you a link to reset your password</Text>
                
                <Space h="md" />
                <Divider />
                <Space h="md" />

                {step === 0 ? (
                    <form>
                        <TextInput value={email} onChange={(e) => {setEmail(e.currentTarget.value)}} styles={{input: {'::placeholder': {fontWeight: 300}}}} withAsterisk  placeholder="" label="Your Email Address"/>

                        <Button onClick={() => {sendVerifyEmail()}} loading={loading} radius={28} mt="xl">
                            Proceed &rarr;
                        </Button>
                    </form>
                ) : step === 1 ? (
                    <form>
                        <TextInput styles={{input: {'::placeholder': {fontWeight: 300}}}} withAsterisk  placeholder="" label="Verification Token" type="number" value={token} onChange={(e) => {setToken(e.currentTarget.value)}}/>
                        <Button loading={loading} onClick={() => {verifyEmail()}} radius={28} mt="xl">
                          Verify Token
                        </Button>
                </form>
                  ) : (
                    <form>
                    <PasswordInput description="Your password length must be at least 8 characters long." value={password} mb={"md"} onChange={(e) => {setPassword(e.currentTarget.value)}} withAsterisk placeholder='' label="Password" mt="sm" />

                    <PasswordInput value={confirm_password} mb={"md"} onChange={(e) => {setConfirmPassword(e.currentTarget.value)}} withAsterisk placeholder='' label="Confirm Password" mt="sm" />

                    <Button disabled={(password.length < 8 ) || (password !== confirm_password)} loading={loading} onClick={() => {updatePassword()}} radius={28} mt="xl">
                            Reset Password
                        </Button>
                    </form>
                  )}
                </Container>
            </Paper>
        </SimpleGrid>
        </>
    )
}