import { Center, ColorSchemeProvider, createEmotionCache, MantineProvider } from '@mantine/core';
import Login from './components/Login';
import { SESSION_DATA, THEME_KEY } from './constants';
import { createContext, useEffect, useReducer, useState } from 'react';
import axios from './utils/axios';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useLocalStorage } from '@mantine/hooks';
import { Toaster } from 'react-hot-toast';
import GuestLayout from './components/GuestLayout';
import Register from './components/register';
import ForgotPassword from './components/forgot-password';
import MemberLayout from './components/MemberLayout';
import Portal from './components/portal';
import Assistants from './components/Assistants';
import CPDs from './components/CPDs';
import CertificateRequests from './components/Cert-Requests';
import Account from './components/Account';
import Payments from './components/Payments';
import Invoices from './components/invoices';

const initialState = {
  isLoading: true,
  userToken: null,
  userData: null,
  isSignout: false
}

export const AuthContext = createContext({
  state: initialState,
  dispatch: () => {}
});

function AuthReducer(state, action) {
  switch(action.type){
    case "RESTORE_TOKEN":
      return {
        ...state,
        isLoading: false,
        userToken: action.token,
        userData: action.data
      };

    case "SIGN_IN":
      return {
        ...state,
        isSignout: false,
        userToken: action.token,
        userData: action.data
      };

    case "SIGN_UP":
      return {
        ...state,
        isSignout: false,
        userToken: action.token,
        userData: action.data
      };

    case "SIGN_OUT":
      localStorage.removeItem(SESSION_DATA);
      return {
        ...state,
        isSignout: true,
        userToken: null,
        userData: null
      };

    default:
      return state;
  }
}

function App() {
  const [state, dispatch] = useReducer(AuthReducer, initialState);

  const [session, ] = useState(localStorage.getItem(SESSION_DATA));

  useEffect(() => {
    const bootstrapAsync = async () => {
      let userToken;
      let data;

      try{
        userToken = session;

        if(userToken !== null){
          try{
            const {
              data: {
                data: accountData,
                token: accessToken
              },
            } = await axios("/member/login-with-token", {
              headers: {
                Authorization: `Bearer ${userToken}`,
              }
            });
  
            userToken = accessToken;
            data = accountData;
          } catch(error){
            userToken = null;
            data = null
          }
        }
      } catch(error){
        userToken = null;
        data = null;
      }

      dispatch({type: "RESTORE_TOKEN", token: userToken, data: data});
    }

    bootstrapAsync();

  }, [session]);

  const LoaderPage = () => {
    return (
        <Center my="20%">
          <div className="inline-block h-8 w-8 animate-spin mt-10 rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white" role="status">
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
            Loading...
            </span>
          </div>
        </Center>
    )
  }

  const AuthPage = () => {
    return (
      <Navigate to={"/portal/home"} />
    )
  }

  const UnauthPage = () => {
    return (
      <Navigate to="/" />
    )
  }

  const [colorScheme, setColorScheme] = useLocalStorage({
    key: THEME_KEY,
    defaultValue: "light",
    getInitialValueInEffect: true
  });

  const toggleColorScheme = (value) => {
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));
  }

  const cache = createEmotionCache({key: "allsk-portal"});

  return (
    <AuthContext.Provider value={{state, dispatch}}>
      <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
        <MantineProvider theme={{ colorScheme, primaryColor: "blue", primaryShade: 9, cursorType: 'pointer'}} withGlobalStyles withNormalizeCSS emotionCache={cache}>
            {state.isLoading ? (
              <LoaderPage />
            ) : (
              <BrowserRouter>
                <Routes>
                  <Route exact path="/" element={state.userToken === null ? <GuestLayout /> : <AuthPage /> }>
                    <Route index element={<Login />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                  </Route>
                  <Route path='/portal/' element={state.userToken === null ? <UnauthPage /> : <MemberLayout />}>
                    <Route index element={<Portal />} />
                    <Route path='/portal/home' element={<Portal />} />
                    <Route path='/portal/assistants' element={<Assistants />} />
                    <Route path='/portal/invoices' element={<Invoices />} />
                    <Route path='/portal/payments' element={<Payments />} />
                    <Route path='/portal/cpds' element={<CPDs />} />
                    <Route path='/portal/certificate-requests' element={<CertificateRequests />} />
                    <Route path='/portal/account' element={<Account />} />
                  </Route>
                </Routes>
              </BrowserRouter>
            )}
            <Toaster />
        </MantineProvider>
      </ColorSchemeProvider>
    </AuthContext.Provider>
  );
}

export default App;
