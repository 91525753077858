import { useState, useContext } from 'react';
import {Navbar,NavLink,ScrollArea,Divider, Badge, Button } from '@mantine/core';
import {IconLogout, IconHome, IconSettings, IconUsers, IconCoin, IconCertificate, IconDownload, IconSchool, IconArrowUpRight, IconCalendar, IconCreditCardPay,} from '@tabler/icons-react';
import useStyles from "./Navbar.Style";
import {  Link, useLocation } from 'react-router-dom';
import { HEADER_HEIGHT } from '../header/Header.Style';
import { useViewportSize } from '@mantine/hooks';
import { AuthContext } from '../../../App';
import { SidebarContext } from '../context/sidebar/sidebar.context';

export function MemberLayoutNavbar() {
    const { classes } = useStyles();
    const { state, } = useContext(SidebarContext);

    const { dispatch: authDispatch } = useContext(AuthContext);

    const { height } = useViewportSize();
    const [opened, setOpened] = useState({
      label: "",
      opened: false
    });

    const location = useLocation();

    const links = [
      { icon: IconHome, label: 'Home', href: `/portal/home` },
      { icon: IconUsers, label: 'Assistants', href: `/portal/assistants` },
      { icon: IconCreditCardPay, label: 'Invoices', href: `/portal/invoices` },
      { icon: IconCoin, label: 'Payments', href: `/portal/payments` },
      { icon: IconSchool, label: 'CPDs', href: `/portal/cpds` },
      { icon: IconCertificate, label: 'Certificate Requests', href: `/portal/certificate-requests` },
      { icon: IconSettings, label: 'Account', href: `/portal/account`, },
    ];

    const itemLinksLight = links.map((link, idx) => {
      const hasLinks = Array.isArray(link.links);
      return(
        hasLinks ? (
          <NavLink opened={(opened.opened && opened.label === link.label) || location.pathname.includes(link.href)} onClick={() => {opened.opened && opened.label === link.label ? setOpened({label: "", opened: false}) : setOpened({opened: true, label: link.label})}} active={window.location.href.includes(link.href)} component={Link} to={link.href} key={idx} label={link.label} icon={<link.icon size={18}/>}
          >
            {link.links.map((itm) => {
              return (
                <NavLink
                component={Link}
                active={window.location.href.includes(itm.href)}
                to={itm.href}
                key={itm.label}
                label={itm.label}
                />
              )
            })}
          </NavLink>
        ) : (
          <NavLink
          component={Link}
          active={window.location.href.includes(link.href)}
          to={link.href}
          description={link.description?.label}
          label={link.label}
          icon={<link.icon size={18} />}
          key={link.label}
          variant='filled'
          onClick={() => {setOpened({opened: false, label: ""})}}
          />
        )
      )
    })

    return (
      <Navbar zIndex={1} hiddenBreakpoint="sm" height={height - HEADER_HEIGHT} hidden={state.opened} width={{ sm: state.width }} sx={(theme) => ({[theme.fn.smallerThan('sm')]: { display: 'none' }, height: '100%', })} pt="lg" className={classes.navbar}> 
        <Navbar.Section mt={-15} grow component={ScrollArea} sx={(theme) => ({ [theme.fn.smallerThan('sm')]: { display: 'none' }, height: '100%' })} className={classes.section}>
        {itemLinksLight}
        </Navbar.Section>
        <Navbar.Section style={{ marginBottom: HEADER_HEIGHT }} className={classes.lastSection}>
        <Divider mb={10} mr={10} ml={10} />
            <NavLink mb={20} label="Sign Out"  icon={<IconLogout color="red" />} onClick={() => {authDispatch({type: "SIGN_OUT"})}} />
        </Navbar.Section>
  
      </Navbar>
    );
  }
