import { Outlet } from "react-router-dom";
import { SidebarProvider } from "./context/sidebar/sidebar.provider";
import { HEADER_HEIGHT } from "./header/Header.Style";
import PortalLayout from "./PortalLayout";

export default function MemberLayout(){
    return (
        <SidebarProvider>
            <PortalLayout>
                <main style={{ paddingTop: HEADER_HEIGHT}}>
                    <Outlet />
                </main>
            </PortalLayout>
        </SidebarProvider>
    )
}