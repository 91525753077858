import {useContext, useCallback, } from 'react';
import { Container, Group, MediaQuery, Burger, Indicator, Menu, Button, ActionIcon, Autocomplete, Progress, Title } from '@mantine/core';
import { Bell } from "tabler-icons-react";
import useStyles from './Header.Style';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useViewportSize } from '@mantine/hooks';
import { SidebarContext } from '../context/sidebar/sidebar.context';
import { ToggleTheme } from '../../ToggleTheme';
import { UserInfo } from './UserInfo';
import { IconArrowUpRight } from '@tabler/icons-react';

export default function MemberLayoutHeader() {
  const { classes } = useStyles();
  const { state, dispatch } = useContext(SidebarContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { width } = useViewportSize();

  useEffect(() => {
    if(!state.opened){
      dispatch({
        type: 'TOGGLE',
      });
    }
  }, [location.pathname])

  const toggleHandler = useCallback(() => {
    dispatch({
      type: 'TOGGLE',
    });
  }, [dispatch]);



  return (
    <>
    <div className={classes.header} >
      <Container size={width} px="md" className={classes.inner}>
        <Group noWrap style={{marginRight: 'auto',}} >
        <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
              <Burger
                opened={!state.opened}
                onClick={toggleHandler}
                size="sm"
                color="#868E96"
                mr="xl"
              />
            </MediaQuery>

            <Group position='apart' noWrap sx={(theme) => ({marginLeft: 5})} >
                <img src='https://www.allsk.co.ke/logo-whitebg.jpeg' className='h-12 w-auto' alt='Logo' />
                <MediaQuery smallerThan={"sm"} styles={{display: "none"}}>
                <Title order={5}>ALLSK Member Portal</Title>
                </MediaQuery>

                <MediaQuery largerThan={"sm"} styles={{display: "none"}}>
                <Title order={5}>ALLSK</Title>
                </MediaQuery>

          </Group>
        </Group>

        <Group style={{marginLeft: 'auto',}} noWrap spacing="xs" >

            <Indicator title="Notifications" label={0} mt={10} inline size={17}>
                  <Bell strokeWidth={1} size={30} />
            </Indicator>

            <MediaQuery smallerThan={"md"} styles={{display: "none"}}>
            <ToggleTheme />
          </MediaQuery>

            <UserInfo/>
        </Group>
      </Container>
    </div>
    </>
  );
}