import { Button, Divider, Group, Paper, Space, Table, Text, TextInput, Title } from "@mantine/core";
import { IconFileExport, IconPlus, IconSearch, IconDownload } from "@tabler/icons-react";
import { Helmet } from "react-helmet";

export default function CPDs(){
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>CPDs | ALLSK</title>
            </Helmet>

            <Group mb={30} position="apart">
                <Title order={3}>CPDs</Title>
                <Button leftIcon={<IconDownload size={14} />} radius={"xl"}>Certificate of Good Standing</Button>
            </Group>
        <Paper p="md">

            <Group mb={20} position="apart" style={{overflowX: "auto"}} mt={20}>
                <Group noWrap position="left" spacing={"xs"}>
                    <Text size="xs" color="dimmed">All: <strong><span>0</span></strong></Text>
                    <Text size="xs" color="dimmed">Approved: <strong><span style={{color: "green"}}>0</span></strong></Text>
                    <Text size="xs" color="dimmed">Pending: <strong><span style={{color: "green"}}>0</span></strong></Text>

                </Group>

                <Group position="right" spacing={"xs"} noWrap>
                <TextInput placeholder="Search by event name" icon={<IconSearch size={14} />} />
                <Button variant="outline" leftIcon={<IconFileExport size={14} />}>Export to Excel</Button>
                </Group>
            </Group>

        
            <div style={{overflowX: "auto"}}>
                <Table fontSize={"xs"} style={{borderBottom: '1px solid #E9ECEF'}}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Event Name</th>
                            <th>Points</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan={5}>
                                <Text align="center">No data was found</Text>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </Paper>
        </>
    )
}