import { AWS_ACCESS_KEY, AWS_INFRASTRUCTURE_BUCKET, AWS_INFRASTRUCTURE_REGION, AWS_SECRET_KEY } from "../constants";

const AWS = require("aws-sdk");

export const fileStorage = async (file) => {
    AWS.config.update({
      accessKeyId: AWS_ACCESS_KEY,
      secretAccessKey: AWS_SECRET_KEY,
    });
  
    const s3 = new AWS.S3({
      region: AWS_INFRASTRUCTURE_REGION,
    });
  
    const params = {
      Bucket: AWS_INFRASTRUCTURE_BUCKET,
      Key: file.name,
      Body: file,
    };
  
    try {
      const data = await s3.putObject(params).promise();
      console.log(data);
      console.log("File uploaded successfully!");
      return `https://${AWS_INFRASTRUCTURE_BUCKET}.s3.${AWS_INFRASTRUCTURE_REGION}.amazonaws.com/${file.name}`;
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
}