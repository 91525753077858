import { Button, Divider, Drawer, Group, Paper, Space, Table, Text, TextInput, Title } from "@mantine/core";
import { IconFileExport, IconPlus, IconSearch } from "@tabler/icons-react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function Payments(){
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Payments | ALLSK</title>
            </Helmet>

            <Group mb={30} position="apart">
                <Title order={3}>Your Payments</Title>
                <Button component={Link} to="/portal/invoices" leftIcon={<IconPlus size={14} />} radius={"xl"}>Make New Payment</Button>
            </Group>
        <Paper p="md">

            <Group mb={20} position="apart" style={{overflowX: "auto"}} mt={20}>
                <Group noWrap position="left" spacing={"xs"}>
                    <Text size="xs" color="dimmed">All: <strong><span>0</span></strong></Text>
                    <Text size="xs" color="dimmed">Annual Membership: <strong><span style={{color: "green"}}>0</span></strong></Text>
                    <Text size="xs" color="dimmed">Others: <strong><span style={{color: "green"}}>0</span></strong></Text>

                </Group>

                <Group position="right" spacing={"xs"} noWrap>
                <TextInput placeholder="Search by transaction ID" icon={<IconSearch size={14} />} />
                <Button variant="outline" leftIcon={<IconFileExport size={14} />}>Export to Excel</Button>
                </Group>
            </Group>

        
            <div style={{overflowX: "auto"}}>
                <Table fontSize={"xs"} style={{borderBottom: '1px solid #E9ECEF'}}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>FirstName</th>
                            <th>Transaction ID</th>
                            <th>Amount</th>
                            <th>Category</th>
                            <th>Date</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan={7}>
                                <Text align="center">No data was found</Text>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </Paper>

        </>
    )
}