import { Avatar, Button, Grid, Group, Indicator, Paper, SimpleGrid, Space, Text } from "@mantine/core";
import { useContext } from "react";
import { AuthContext } from "../../App";
import { IconEdit } from "@tabler/icons-react";

export default function Account(){
    const { state } = useContext(AuthContext);
    return (
       <Grid>
        <Grid.Col sm={12} lg={12}>
            <Text style={{fontWeight: 500}} mb={20}>Profile</Text>
                <Paper withBorder p="md" radius={20} mb={10}>
                <Group position="apart">
                <Group position="left">
                <Indicator inline size={16} offset={7} position="bottom-end" color={state.userData.status === "Active" ? "#00ED64" : "red"} withBorder>
                    <Avatar src={""} radius="xl" />
            </Indicator>
            <div>
                    <Group spacing={2}><Text>{state.userData.fullname}</Text></Group>
                    <Text size="xs">{state.userData.status}</Text>
                </div>
                </Group>

                <Group position="right">
                    <Button size="sm" variant="default" rightIcon={<IconEdit size={13} />} radius={20}>Edit</Button>
                </Group>
                </Group>
                </Paper>
                <Paper p="md" withBorder radius={20} mb={10}>
                    <Group position="apart">
                        <Text style={{fontWeight: 500}}>Personal Information</Text>
                        <Button size="sm" variant="default" rightIcon={<IconEdit size={13} />} radius={20}>Edit</Button>
                    </Group>
                    <Space h="sm" />
                    <SimpleGrid spacing="md" cols={2}>
                        <div>
                            <Text size="sm" style={{fontWeight: 500}} color="dimmed">Email Address</Text>
                            <Text size="sm">{state.userData?.username}</Text>
                        </div>
                        <div>
                            <Text size="sm" style={{fontWeight: 500}} color="dimmed">Phone</Text>
                            <Text size="sm">{state.userData?.phone}</Text>
                        </div>
                    </SimpleGrid>
                </Paper>

                <Paper p="md" withBorder radius={20} mb={10}>
                    <Group position="apart">
                        <Text style={{fontWeight: 500}}>Address</Text>
                        <Button size="sm" variant="default" rightIcon={<IconEdit size={13} />} radius={20}>Edit</Button>
                    </Group>
                    <Space h="sm" />
                    <SimpleGrid spacing="md" cols={2}>
                        <div>
                            <Text size="sm" style={{fontWeight: 500}} color="dimmed">Address</Text>
                            <Text size="sm">{state.userData?.address}</Text>
                        </div>
                        <div>
                            <Text size="sm" style={{fontWeight: 500}} color="dimmed">Country</Text>
                            <Text size="sm">Kenya</Text>
                        </div>
                    </SimpleGrid>
                </Paper>

            </Grid.Col>
        </Grid>
       
    )
}