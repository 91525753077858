import {
    UnstyledButton,
    Group,
    Avatar,
    Text,
    createStyles,
    Indicator,
  } from '@mantine/core';
  import {  IconChevronRight } from '@tabler/icons-react';
  import { useContext } from 'react';
  import { Link } from "react-router-dom";
  import { AuthContext } from '../../../App';
  
  const useStyles = createStyles((theme) => ({
    user: {
      display: 'block',
      width: '100%',
      padding: theme.spacing.md,
      color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
  
      '&:hover': {
        backgroundColor: "transparent"
      },
    },
  }));
  
  export function UserInfo({ ...others }) {
    const { classes } = useStyles();
    const { state } = useContext(AuthContext);
  
    return (
      <Group position='center'>
      <UnstyledButton className={classes.user} {...others} component={Link} to={`/app/settings/profile`} >
        <Group noWrap spacing={3} sx={(theme) => ({ [theme.fn.smallerThan('lg')]: { display: 'none' } })} >
        <Indicator inline size={16} offset={7} position="bottom-end" color={"#00ED64"} withBorder>
        <Avatar variant='filled' radius="xl" color={"blue"} >{state.userData.fullname.slice(0,2)}</Avatar>
        </Indicator>
          <div style={{ flex: 1 }}>
            <Text inline lineClamp={1} size="sm" weight={750}>
              {state.userData.fullname}{state.userData.status === "Pending" ? <span style={{color: "red"}}>(Pending)</span> : null}
            </Text>
          </div>
  
          <IconChevronRight size={14} stroke={1.5} />
        </Group>
        <Group sx={(theme) => ({ [theme.fn.largerThan('md')]: { display: 'none' } })} >
        <Indicator inline size={16} offset={7} position="bottom-end" color={"#00ED64"} withBorder>
          <Avatar variant='filled' radius="xl" color={"blue"} >{state.userData.fullname.slice(0, 2)}</Avatar>
        </Indicator>
        </Group>
      </UnstyledButton>
      </Group>
    );
  }