import React, { useContext, useEffect, useState } from "react";

import { Badge, Button, Center, Checkbox, Divider, Drawer, FileInput, Group, Loader, Pagination, Paper, Space, Stack, Table, Text, TextInput, Title } from "@mantine/core";
import { IconFileExport, IconPlus, IconSearch } from "@tabler/icons-react";
import { Helmet } from "react-helmet";
import axios from "../../utils/axios";
import { AuthContext } from "../../App";
import toast from "react-hot-toast";
import { Edit, Trash } from "tabler-icons-react";
import { fileStorage } from "../../utils/file-storage";
import { MIME_TYPES } from "@mantine/dropzone";

export default function Assistants(){
    const { state } = useContext(AuthContext);

    const [data, setData] = React.useState([]);

    const [r, setR] = useState(false);

    // asst attributes

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [idnumber, setIDNumber] = useState("");

    const [loading, setLoading] = useState(false);

    const [existing, setExisting] = useState(false);

    const [approval, setApproval] = useState(null);

    const [assistant_number, setAssistantNumber] = useState("");

    const [d, setD] = useState(false);

    const fetchData = () => {
        setLoading(false);
        axios.get("/assistants/all", {
            headers: {
                'Authorization': `Bearer ${state.userToken}`
            },
        }).then(function(res){
            if(res.status === 200){
                setData(res.data.data.reverse());
                setLoading(false);
            }
        }).catch(function(err){
            console.log(err);
            setLoading(false);
        })
    }

    useEffect(() => {
        fetchData();
    }, [r]);

    const createAssistant = async () => {
        setLoading(true);

        var body = null;

        if(existing){
            if(approval && assistant_number !== ""){
                body = {
                    fullname: name,
                    email: email,
                    id_number: idnumber,
                    surveyor: state.userData._id,
                    status: "Active",
                    approval_letter: await fileStorage(approval),
                    assistant_number: assistant_number
                }
            } else {
                body = {
                    fullname: name,
                    email: email,
                    id_number: idnumber,
                    surveyor: state.userData._id
                }
            }
        } else {
            body = {
                fullname: name,
                email: email,
                id_number: idnumber,
                surveyor: state.userData._id
            }
        }

        axios.post("/assistants/create", body, {
            headers: {
                'Authorization': `Bearer ${state.userToken}`
            }
        })
        .then(function(res){
            if(res.status === 200){
                setLoading(false);

                setD(false);

                setR(!r);

                toast.success("Assistant details have been submitted successfully!")
            }
        })
        .catch(function(err){
            setLoading(false);
            setD(false);
            toast.error("Sorry, we could not complete this request. Please try again later!")
        })
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Approved Assistants | ALLSK</title>
            </Helmet>

            <Drawer title="Add New Assistant" position="right" opened={d} onClose={() => {setD(false)}} withOverlay={false} size={"lg"}>
                <Stack justify="space-between">
                    <div>
                        <TextInput label="Full Name" value={name} onChange={(e) => {setName(e.currentTarget.value)}} required description="Full names of the assistant as they appear on the national ID" mb="md" />
                        <TextInput label="Email" value={email} onChange={(e) => {setEmail(e.currentTarget.value)}} required description="Valid email address of the assistant" mb="md" />
                        <TextInput label="ID Number" value={idnumber} onChange={(e) => {setIDNumber(e.currentTarget.value)}} required description="ID Number of the assistant" mb="md" />
                        
                        <Checkbox label="Existing Approved Assistant?" checked={existing} onChange={(e) => {setExisting(e.currentTarget.checked)}} mb="md" description="Tick yes if the assistant you are uploading is already approved by the board." />

                        {existing ? (
                            <>
                            <TextInput label="Assistant Number" mb="md" value={assistant_number} onChange={(e) => {setAssistantNumber(e.currentTarget.value)}} />
                            <FileInput accept={MIME_TYPES.pdf} label="Letter of approval" description="Scanned copy of the letter of approval" value={approval} onChange={setApproval} mb="md" />

                            </>
                        ) : null}
                    </div>

                    <Group>
                        <Button variant="default" onClick={() => {setD(false)}}>Cancel</Button>

                        <Button disabled={name.trim() === "" || email.trim() === "" || idnumber.trim().length < 6 || (existing && assistant_number.trim() === "") || (existing && !approval && assistant_number.trim() === "")} loading={loading} onClick={() => {createAssistant()}}>Add Assistant</Button>
                    </Group>
                </Stack>
            </Drawer>

            <Group mb={30} position="apart">
                <Title order={3}>Approved Assistants</Title>
                <Button onClick={() => {setD(true)}} leftIcon={<IconPlus size={14} />} radius={"xl"}>Add New Assistant</Button>
            </Group>
        <Paper p="md">

            <Group mb={20} position="apart" style={{overflowX: "auto"}} mt={20}>
                <Group noWrap position="left" spacing={"xs"}>
                    <Text size="xs" color="dimmed">All: <strong><span>0</span></strong></Text>
                    <Text size="xs" color="dimmed">Active: <strong><span style={{color: "green"}}>0</span></strong></Text>
                    <Text size="xs" color="dimmed">Inactive: <strong><span style={{color: "green"}}>0</span></strong></Text>

                </Group>

                <Group position="right" spacing={"xs"} noWrap>
                <TextInput placeholder="Search by name/number" icon={<IconSearch size={14} />} />
                <Button variant="outline" leftIcon={<IconFileExport size={14} />}>Export to Excel</Button>
                </Group>
            </Group>

        
            <div style={{overflowX: "auto"}}>
                <Table fontSize={"xs"} style={{borderBottom: '1px solid #E9ECEF'}}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Number</th>
                            <th>ID</th>
                            <th>Approval</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr>
                                <td colSpan={8}>
                                    <Center>
                                        <Loader />
                                    </Center>
                                </td>
                            </tr>
                        ) : (
                            data.length === 0 ? (
                                <tr>
                                <td colSpan={8}>
                                    <Text align="center">No data was found</Text>
                                </td>
                            </tr>
                            ) : (
                                data.map((itm, idx) => {
                                    return (
                                        <tr key={`ass-${idx}`}>
                                            <td>
                                                {idx + 1}
                                            </td>
                                            <td>
                                                {itm.fullname}
                                            </td>
                                            
                                            <td>
                                                {itm?.email || "N/A"}
                                            </td>
                                            <td>
                                                {itm?.status === "Active" ? itm.assistant_number : "N/A"}
                                            </td>
                                            <td>
                                                {itm?.id_number}
                                            </td>
                                            <td>
                                                <Text component="a" lineClamp={1} href={itm.approval_letter}>{itm.approval_letter}</Text>
                                            </td>
                                            <td>
                                                <Badge style={{textTransform: "none"}} color={itm?.status === "Active" ? "green" : itm?.status === "Suspended" ? "red" : "yellow"}>
                                                    {itm?.status}
                                                </Badge>
                                            </td>
                                            <td>
                                                <Group noWrap>
                                                <Button leftIcon={<Edit size={14} />} variant="light" size="xs" radius={"xl"}>Update</Button>
                                                <Button color="red" leftIcon={<Trash size={14} />} variant="light" size="xs" radius={"xl"}>De-register</Button>
                                                </Group>
                                            </td>
                                        </tr>
                                    )
                                })
                            )
                        )}
                    </tbody>
                </Table>
            </div>

            <Group mt={30} position="right">
            <Pagination size="sm" page={1} total={1} siblings={0} spacing="xs" withControls radius="xs" />
            </Group>
        </Paper>
        </>
    )
}