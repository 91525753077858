import { Anchor, BackgroundImage, Button, Center, Checkbox, Container, Divider, FileInput, Group, MediaQuery, Paper, PasswordInput, Select, SimpleGrid, Space, Stack, Text, TextInput, Title } from "@mantine/core";
import { useLocalStorage, useViewportSize } from "@mantine/hooks";
import { Helmet } from "react-helmet";
import Network from "../../assets/authentication.png";
import { Link } from "react-router-dom";
import axios from "../../utils/axios";
import { customAlphabet } from "nanoid";
import { currentBrowser, OS } from "../../utils/osAndBrowser";
import toast from "react-hot-toast";
import { useContext, useEffect, useState } from "react";
import { MIME_TYPES } from "@mantine/dropzone";
import { SESSION_DATA } from "../../constants";
import { AuthContext } from "../../App";
import { fileStorage } from "../../utils/file-storage";

const nanoid = customAlphabet("1234567890")

export default function Register(){
    const { dispatch } = useContext(AuthContext);

    const { height } = useViewportSize();

    const [step, setStep] = useState(0);
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");

    const [salutation, setSalutation] = useState("Mr.");

    const [license, setLicense] = useState("")

    const [id_number, setIDNumber] = useState("")

    const [license_cert, setLicenseCert] = useState(null);

    const [address, setAddress] = useState("")

    const [loading, setLoading] = useState(false);

    const [token, setToken] = useState("");

    const [terms, setTerms] = useState(false);

    const [password, setPassword] = useState("");
    const [confirm_password, setConfirmPassword] =useState("");
    const [phone, setPhone] = useState("");

  const sendVerifyEmail = async () => {

    if(!/^\S+@\S+$/.test(email)){
      toast.error("Invalid email detected", {
        position: "bottom-right"
      })
       return false;
    } 
  
    if(name.trim().length < 3){
      toast.error("Your name is too short.", {
        position: "bottom-right"
      });
      return false;
    }
  
    setLoading(true);
  
    axios.post("/member/code", {
      name: salutation + " " + name,
      password: false,
      username: email,
      code: nanoid(4),
      browser: currentBrowser(window),
      os: OS(window),
    }).then(function(res){
      if(res.status === 200){
        toast.success("A verification code has been send to "+email, {
          position: "bottom-right",
          duration: 5000
        });
        setLoading(false);
        setStep(1)
      } else {
        toast.error("The email is already registered and in use!", {
          position: "bottom-right",
          duration: 5000
        })
        setLoading(false)
      }
    }).catch(function(error){
      toast.error(error?.response?.data?.message || error.message, {
        position: "bottom-right",
        duration: 5000
      })
      setLoading(false)
    })
  
  }

  const verifyEmail = () => {
    if(token.length < 4){
      toast.error("The token length should be atleast 4 characters", {
        position: "bottom-right",
      });
  
      return false
    }
  
    setLoading(true);
    axios.post("/member/verify", {
      username: email,
      token: token
    }).then(function(res){
      if(res.status === 200){
        toast.success("Verification succeeded!", {
          position: "bottom-right",
          duration: 5000
        });
        setLoading(false)
        setStep(2);
      }
    }).catch(function(error){
      toast.error(error?.response?.data?.message || error.message, {
        position: "bottom-right",
        duration: 5000
      });
      setLoading(false)
      setStep(0);
    })
  }

  const createAccount = async () => {

        if(!/^\S+@\S+$/.test(email)){
            toast.error("Invalid email detected", {
              position: "bottom-right"
            })
             return false;
          } 

        if(license_cert === null){
            toast.error("Invalid license certificate detected", {
                position: "bottom-right"
              })
               return false;
        }

        if(password.length < 8){
            toast.error("Invalid password detected", {
                position: "bottom-right"
              })
               return false;
        }

        if(license.trim() === ""){
            toast.error("Invalid license number detected", {
                position: "bottom-right"
              })
               return false;
        }

        if(id_number.length < 6 || id_number.length > 8){
            toast.error("Invalid ID number detected", {
                position: "bottom-right"
              })
               return false;
        }

        if(address.trim() === ""){
            toast.error("Invalid address detected", {
                position: "bottom-right"
              })
               return false;
        }

        if(phone.trim() === ""){
            toast.error("Invalid phone number detected", {
                position: "bottom-right"
              })
               return false;
        }

        setLoading(true);

        const l_cert = await fileStorage(license_cert);

        const body = {
            username: email,
            password: password,
            fullname: name,
            license_number: license,
            license_certificate: l_cert,
            address: address,
            id_number: id_number,
            phone: phone,
            salutation: salutation
        }

        axios.post("/member/register", body).then(({
            data: {
                data: accountData,
                token: accessToken
            }
        }) => {
            localStorage.setItem(SESSION_DATA, accessToken);

            dispatch({ type: "SIGN_IN", token: accessToken, data: accountData });

            setLoading(false);
        }).catch((error) => {
            setLoading(false)
            toast.error(error?.response?.data?.message || error.message)
        })
  }


    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>SIGN UP | ALLSK</title>
            <meta name="description" content="Login to your member account" />

        </Helmet>

        <SimpleGrid cols={2} spacing={"lg"} breakpoints={[{maxWidth: 980, cols: 1, spacing: "md"}]}>
            <MediaQuery smallerThan={"md"} styles={{display: "none"}}>
                <BackgroundImage src="" bg={"blue"} h={height}>
                    <Stack p="xl" h={height} justify="space-between">
                        <Title color="#ffffff" order={6}>ALLSK Portal</Title>
                        <Center>
                              <img src={Network} className='h-96 w-auto' />
                        </Center>

                        <Text pb={40} color='#ffffff'>
                        Access Your Member Benefits, Resources, and Professional Network
                        </Text>
                    </Stack>
                </BackgroundImage>
            </MediaQuery>

            <Paper p="xl">
                <Group className='py-8 lg:py-0' position='right'>
                    <Center>
                        <Anchor component={Link} to="/login" size={"sm"}>Login!</Anchor>
                    </Center>
                </Group>

                <Container size={400} className='h-screen-2xl py-24 lg:py-16 items-center justify-center'>
                <Title align='center' mb={10} order={4}>Become A Member of ALLSK</Title>
                <Text mb={20} align='center'>Already a licensed surveyor but not yet a member of ALLSK? Register now!</Text>

                <Space h="md" />
                <Divider />
                <Space h="md" />

                {step === 0 ? (
                    <form>
                      <Select label="Title/Salutation" value={salutation} onChange={(val) => {setSalutation(val)}} mb="md" data={[{label: "Mr.", value: "Mr."}, {label: "Prof.", value: "Prof."}, {label: "Dr.", value: "Dr."}, { label: "Mrs.", value: "Mrs."}, { label: "Miss.", value: "Miss."}, {label: "Ms.", value: "Ms."}]} />
                    <TextInput value={name} mb={"md"} onChange={(e) => {setName(e.currentTarget.value)}} withAsterisk placeholder='' label="Your Fullname" mt="sm" />
                    <TextInput value={email} onChange={(e) => {setEmail(e.currentTarget.value)}} styles={{input: {'::placeholder': {fontWeight: 300}}}} withAsterisk  placeholder="" label="Your Email Address"/>

                    <Button disabled={name.trim() === "" || !/^\S+@\S+$/.test(email)} onClick={() => {sendVerifyEmail()}} loading={loading} radius={28} mt="xl">
                    Proceed &rarr;
                    </Button>
                    </form>
                ) : step === 1 ? (
                    <form>
                        <TextInput styles={{input: {'::placeholder': {fontWeight: 300}}}} withAsterisk  placeholder="" label="Verification Token" type="number" value={token} onChange={(e) => {setToken(e.currentTarget.value)}}/>
                        
                        <Group mt="xl">
                        <Button onClick={() => {setStep(0)}} radius={28}>Previous</Button>
                        <Button onClick={() => {verifyEmail()}} radius={28}>
                          Verify Email
                        </Button>
                        </Group>
                </form>
                  ) : step === 2 ? (
                    <form>
                        <TextInput value={license} mb={"md"} onChange={(e) => {setLicense(e.currentTarget.value)}} withAsterisk placeholder='' label="Your License Number" mt="sm" />
                        <FileInput accept={MIME_TYPES.pdf} value={license_cert} onChange={setLicenseCert} styles={{input: {'::placeholder': {fontWeight: 300}}}} withAsterisk  placeholder="" label="License certificate copy(PDF)"/>

                        <Group mt="xl">
                        <Button onClick={() => {setStep(1)}} radius={28}>Previous</Button>
                        <Button disabled={license.trim() === "" || license_cert === null} onClick={() => {setStep(3)}} radius={28}>
                            Continue &rarr;
                        </Button>
                        </Group>
                    </form>
                  ) : step === 3 ? (
                    <form>
                        <TextInput value={id_number} mb={"md"} type="number" onChange={(e) => {setIDNumber(e.currentTarget.value)}} withAsterisk placeholder='' label="Your National ID Number" mt="sm" />

                        <TextInput value={phone} mb={"md"} onChange={(e) => {setPhone(e.currentTarget.value)}} withAsterisk placeholder='' label="Your phonenumber" mt="sm" />

                        <TextInput value={address} mb={"md"} onChange={(e) => {setAddress(e.currentTarget.value)}} withAsterisk placeholder='' label="Your address" mt="sm" />

                        <Group mt="xl">
                        <Button onClick={() => {setStep(2)}} radius={28}>Previous</Button>
                        <Button disabled={id_number.length < 7 || phone.trim() === "" || address.trim() === ""} onClick={() => {setStep(4)}} radius={28}>
                            Continue &rarr;
                        </Button>
                        </Group>
                        
                    </form>
                  ) : (
                    <form>
                        <PasswordInput description="Password must contain at least 8 characters." value={password} mb={"md"} onChange={(e) => {setPassword(e.currentTarget.value)}} withAsterisk placeholder='' label="Password" mt="sm" />

                        <PasswordInput value={confirm_password} mb={"md"} onChange={(e) => {setConfirmPassword(e.currentTarget.value)}} withAsterisk placeholder='' label="Confirm Password" mt="sm" />

                        <Checkbox mb={"md"} label="By registering as a member of ALLSK, you agree to abide by the ALLSK constitution. You also agree to all the terms and conditions." checked={terms} onChange={(e) => {setTerms(e.currentTarget.checked)}} />

                        <Group mt="xl">
                        <Button onClick={() => {setStep(3)}} radius={28}>Previous</Button>
                        <Button loading={loading} disabled={!terms || password.length < 8 || password !== confirm_password} onClick={() => {createAccount()}} radius={28}>
                            Join ALLSK
                        </Button>
                        </Group>
                    </form>
                  )}
                </Container>
            </Paper>
        </SimpleGrid>
        </>
    )
}