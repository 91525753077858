import {
    TextInput,
    PasswordInput,
    Anchor,
    Group,
    Button,
    Paper,
    Text,
    MediaQuery,
    BackgroundImage,
    SimpleGrid,
    Checkbox,
    Center,
    Stack,
    Title,
    Container,
    Notification
  } from '@mantine/core';
  import {  useContext, useEffect, useState} from 'react';
  import { useForm } from "@mantine/form";
  import { Helmet } from 'react-helmet';
  import { useViewportSize } from '@mantine/hooks';
  import Network from "../../assets/authentication.png";
  import { IconX } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import axios from '../../utils/axios';
import { SESSION_DATA } from '../../constants';
import { AuthContext } from '../../App';
import toast from 'react-hot-toast';
  
  const Login = () => {
    const { dispatch } = useContext(AuthContext);

    const { height } = useViewportSize();
  
    const [loading, setLoading] = useState(false);
  
    const [with_err, setWithErr] = useState(false);
  
    const form = useForm({
      initialValues: { email: "", password: ""},
      validate: {
        email: (value) => (/^\S+@\S+$/.test(value) ? null : 'This field is required'),
      },
    });
  
    useEffect(() => {
      if(with_err){
        setTimeout(() => {
          setWithErr(false);
        }, 5000);
      }
    }, [with_err])
  
    const onSubmit = async (obj) => {
      setLoading(true);

      const body = {
        username: obj.email,
        password: obj.password
      };

      axios.post("/member/login", body).then(({
        data: {
          data: accountData,
          token: accessToken
        }
      }) => {
        localStorage.setItem(SESSION_DATA, accessToken);

        dispatch({ type: "SIGN_IN", token: accessToken, data: accountData });

        setLoading(false);
        
      }).catch((error) => {
        setLoading(false)
        toast.error(error?.response?.data?.message || error.message)
    })
    }
  
    return (
      <>
        <Helmet>
        <meta charSet="utf-8" />
        <title>Sign In</title>
        <meta name='description' content='Get access to your Collect123 account.' />
        </Helmet>
        <SimpleGrid
        cols={2}
        spacing="lg"
        breakpoints={[
          { maxWidth: 980, cols: 1, spacing: 'md' }
        ]}
      >
  
  <MediaQuery smallerThan="md" styles={{display: 'none'}}>
                  <BackgroundImage src={""} bg={"blue"} h={height}>
                      <Stack p="xl" h={height} justify='space-between'>
                        <Title color='#ffffff' order={6}>ALLSK Portal</Title>
                        <Center>
                              <img src={Network} className='h-96 w-auto' />
                            </Center>
                        <Text pb={40} color='#ffffff'>
                        Access Your Member Benefits, Resources, and Professional Network
                        </Text>
                      </Stack>
                  </BackgroundImage>
              </MediaQuery>
  
              <Paper p="xl" >
              <Group className='py-8 lg:py-0' position='right'>
                <Center>
                <Anchor component={Link} to="/register" size={"sm"}>Become a Member Now!</Anchor>
              </Center>
                </Group>
              <Container size={400} className='h-screen-2xl py-24 lg:py-16 items-center justify-center'>
                <Title align='center' mb={10} order={4}>Welcome Back</Title>
              <Text mb={20} align='center'>Enter your login credentials to continue</Text>
      <form onSubmit={form.onSubmit((values) => {onSubmit(values)})}>
        {with_err ? (
          <Notification icon={<IconX size={18} />} mb={20} title="Invalid Login Credentials" color='red' variant="filled" />
        ) : null}
      <TextInput styles={{input: {'::placeholder': {fontWeight: 300}}}} withAsterisk {...form.getInputProps("email")} placeholder="hello@example.com" radius={"md"} label="Email Address"/>
      <PasswordInput withAsterisk {...form.getInputProps("password")} label="Password" radius={"sm"} mt="md"/>
      <Group position="apart" mt="md">
        <Checkbox checked label="Remember me in this device?" />
        <Anchor component={Link} to={"/forgot-password"} size={"sm"}>
          Forgot your password?
        </Anchor>
      </Group>
      <Button radius={"xl"} fullWidth loading={loading} disabled={loading} type='submit' mt="xl">
        Sign In &rarr;
      </Button>
  
      </form>
  </Container>
        </Paper>
  
  </SimpleGrid>
  </>
    );
  };
  
  export default Login;