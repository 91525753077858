import { useCallback, useContext } from "react";
import { SidebarContext } from "./context/sidebar/sidebar.context";
import { AppShell, Drawer, MediaQuery, useMantineTheme } from "@mantine/core";
import { MemberLayoutNavbar } from "./navbar";
import { HEADER_HEIGHT } from "./header/Header.Style";
import MemberLayoutHeader from "./header";

export default function PortalLayout({ children }){
    const { state, dispatch } = useContext(SidebarContext);

    const toggleHandler = useCallback(() => {
        dispatch({
            type: "TOGGLE"
        });
    }, [dispatch]);

    const theme = useMantineTheme();

    return (
        <AppShell styles={{
            main: {
                background: theme.colorScheme === "dark" ? theme.colors.dark[9] : theme.colors.gray[0]
            }
        }}
        navbarOffsetBreakpoint={"sm"}
        asideOffsetBreakpoint={"sm"}
        navbar={
            <MediaQuery smallerThan={"md"} styles={{ display: "none"}}>
                <MemberLayoutNavbar />
            </MediaQuery>
        }
        header={
            <MemberLayoutHeader />
        }
        >
        <Drawer onClose={toggleHandler} opened={!state.opened}>
          <p>Mobile Drawer</p>
        </Drawer>
            {children}
        </AppShell>
    )
}